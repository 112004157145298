<template>
    <div class="campaign-landing-page-root" :data-narrow="$vuetify.breakpoint.smAndDown">
        <header class="main-logos">
            <primary-logo class="logo" :height="90" />

            <article class="in-partnership">
                <h2>{{ t('inPartnershipWith') }}</h2>

                <ul>
                    <li v-for="logo in Object.assign([], t('partnerLogos'))" :key="logo.file">
                        <img :src="`/img/campaigns/${$route.params.campaign}/${logo.file}`" :alt="logo.alt" :width="logo.width">
                    </li>
                </ul>
            </article>
        </header>

        <main>
            <div class="content">
                <h1 class="page-headline">{{ t('headline') }}</h1>
            </div>

            <div class="content">
                <div class="description-and-form">
                    <article class="description-area">
                        <h2>{{ t('descriptionHeading') }}</h2>

                        <markdown-output class="description" :value="t('description')" />

                        <div class="text-center">
                            <small>
                                <strong>Get the app:</strong>
                            </small>
                            <app-download-links outlined />
                        </div>
                    </article>

                    <div class="registration-form-block">
                        <h2 v-if="currentUser" class="form-heading">{{ $t('campaignLandingPages.alreadySignedIn.headline') }}</h2>
                        <h2 v-else class="form-heading">{{ t('formHeading') }} </h2>
                        <registration-form embedded class="registration-form" @success="handleRegistrationSuccess" />
                    </div>
                </div>
            </div>

            <div class="hero-container">
                <svg width="0" height="0" viewBox="-14 -2 283 104">
                    <defs>
                        <clipPath id="hero-shape" clipPathUnits="objectBoundingBox">
                            <path d="m 0.1794974,0.13752842 c 0.0815505,-0.0149987 0.12290536,0.0877014 0.20420599,0.0877014 0.12953134,0 0.20989254,-0.22702544 0.35170639,-0.22443476207 C 0.87722367,0.00337485 1.0020179,0.14783664 0.99917961,0.50127638 0.99618141,0.85470789 0.8923445,1.0024066 0.78456994,0.99917515 0.61250444,0.99399378 0.49999277,0.90113834 0.39221821,0.89857492 0.28443368,0.89599516 0.26078804,0.92739966 0.17382085,0.93211743 0.07827888,0.93729878 -0.00108293,0.87021379 8.0592129e-4,0.55546792 0.00280471,0.26388818 0.12005351,0.14842564 0.1794974,0.13754206 Z" />
                        </clipPath>
                    </defs>
                </svg>

                <div
                    class="hero-image"
                    :style="{
                        '--main-image-wide': `url(/img/campaigns/${$route.params.campaign}/${t('mainImage.wide')})`,
                        '--main-image-narrow': `url(/img/campaigns/${$route.params.campaign}/${t('mainImage.narrow')})`,
                        '--main-image-position': t('mainImage.position'),
                    }"
                >
                    <div class="top decoration" />
                    <div class="bottom decoration" />
                </div>
            </div>

            <div class="quotes">
                <figure class="quote">
                    <blockquote>
                        <p>{{ t('quote') }}</p>
                    </blockquote>

                    <figcaption>{{ t('quoteAttribution') }}</figcaption>
                </figure>
            </div>

            <article class="in-the-media">
                <h2>{{ t('asSeenInHeading') }}</h2>
                <ul>
                    <li v-for="logo in Object.assign([], t('asSeenInLogos'))" :key="logo.file">
                        <img :src="`/img/campaigns/${$route.params.campaign}/${logo.file}`" :alt="logo.alt" :width="logo.width">
                    </li>
                </ul>
            </article>
        </main>

        <footer>
            <footer-links />
        </footer>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import PrimaryLogo from '@/components/logo/Primary.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import RouteNames from '@/router/names';
import { CurrentUser } from '@/types';
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';

export default Vue.extend({
    metaInfo(this: any, ) {
        return {
            title: this.pageTitle,
        };
    },

    components: {
        PrimaryLogo,
        RegistrationForm,
        FooterLinks,
        MarkdownOutput,
        AppDownloadLinks,
    },

    data(this: any) {
        return {
            pageTitle: this.t('title'),
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        t(path: string) {
            return this.$t(`campaignLandingPages.${this.$route.params.campaign}.${path}`);
        },

        handleRegistrationSuccess() {
            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },
    },
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.campaign-landing-page-root {
    background: var(--color-royal);
    color: var(--color-white);
    font: normal 16px/24px var(--type-sans);
}

.main-logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0 50px;
    justify-content: center;
    margin-block-end: 30px;
    margin-inline: auto;
    text-align: center;
    width: fit-content;
}

.logo {
    margin: 3em 0 2em;
    max-width: 90%;
}

.in-partnership {
    text-align: start;
}

.in-partnership > h2 {
    color: var(--color-tertiary);
    font: normal 16px/24px 'Bebas Neue', sans-serif;
}

.in-partnership > ul {
    list-style-type: none;
    padding: 0;
}

.in-partnership > ul > li {
    margin: 0 1em;
}

main {
    font: normal 20px/24px var(--type-sans);
}

[data-narrow] main {
    font-size: 14px;
    line-height: 17px;
}

.content {
    margin: 0 auto;
    max-width: 55em;
    padding: 0 1em;
}

.page-headline {
    color: var(--color-tertiary);
}

h1 {
    font: normal 40px/53px var(--type-headline);
    margin: -0.3em 0 1.5em 0;
    text-align: center;
}

[data-narrow] h1 {
    font-size: 20px;
    line-height: 26px;
}

.description-and-form {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

[data-narrow] .description-and-form {
    display: block;
}

.description-area {
    max-width: 45ch;
}

[data-narrow] .description-area {
    max-width: none;
}

.description-area > h2 {
    font: normal 24px/32px var(--type-headline);
    margin: -0.2em 0 1em 0;
}

[data-narrow] .description-area > h2 {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}

.description :deep(ul) {
    padding: 0;
    list-style-type: none;
}

.description :deep(ul > li) {
    margin: 1em 0 1em 2em;
    padding-inline-start: 1em;
    position: relative;
}

.description :deep(ul > li::marker) {
    content: "check";
    color: var(--color-danger);
    font-family: 'Material Icons';
    position: absolute;
    right: 100%;
}

.description :deep(a) {
    color: inherit;
}

.registration-form-block {
    background: var(--color-white);
    border-radius: 15px;
    box-shadow: 0 20px 40px -30px black;
    color: var(--color-dark);
    padding: 1.5em;
    text-align: center;
    width: 35ch;
}

[data-narrow] .registration-form-block {
    width: auto;
}

.registration-form {
    font-size: 14px;
    line-height: 1.4;
}

.form-heading {
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
}

.hero-container {
    height: 40vw;
    margin-block-start: -7em;
    min-height: 30em;
    position: relative;
}

.hero-image {
    background-image: var(--main-image-wide);
    background-position: var(--main-image-position);
    background-size: cover;
    clip-path: url(#hero-shape);
    height: 100%;
    left: 50%;
    min-width: 60em;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 120%;
}

[data-narrow] .hero-image {
    background-image: var(--main-image-narrow);
}

.hero-image > .decoration {
    clip-path: url(#hero-shape);
    height: 10em;
    left: 0;
    opacity: 0.2;
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
}

.hero-image > .decoration.top {
    background: linear-gradient(295deg, #F7D358 8%, #A89C75 111%);
    top: 2em;
}

.hero-image > .decoration.bottom {
    background: linear-gradient(93deg, #0470DC 1%, #13F1FC 117%);
    top: 100%;
}

.quotes {
    display: flex;
    justify-content: center;
    padding: 2em 0 4em;
}

.quote {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 1em;
    max-width: 23em;
}

.quote > figcaption {
    color: var(--color-tertiary);
    font-family: 'Bebas Neue', sans-serif;
}

.in-the-media {
    background-color: var(--color-dark-contrast);
    color: var(--color-dark);
    padding: 1.5em 0;
}

.in-the-media > h2 {
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
}

.in-the-media > ul {
    align-items: center;
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
    margin: -1em;
    padding: 0;
}

[data-narrow] .in-the-media > ul {
    flex-direction: column;
}

.in-the-media > ul > li {
    flex-basis: 30%;
    margin: 1em;
    text-align: center;
}

.in-the-media Ximg {
    /* Trying to make these logos a bit more uniform without having to edit them. */
    filter: grayscale(1) contrast(2) contrast(0.5);
}

footer {
    padding: 1em;
}
</style>
